const palette = {
  background: {
    default: '#fff',
  },
  text: {
    primary: '#17252A',
    secondary: '#566064',
  },
  primary: {
    light: '#17252A',
    main: '#17252A',
    dark: '#17252A',
    contrastText: '#fff',
  },
  secondary: {
    light: '#F8FBFD',
    main: '#E9EBEB',
    dark: '#ECEFFA',
    contrastText: '#17252A',
  },
};

export default palette;
